import styled from "styled-components";

export const DeleteNoteIcon = styled.div`
  cursor: pointer;
  text-align: center;

  svg {
    opacity: 0.5;
    color: red;
  }
`;
