import React from "react";

import PageMetaData from "../../components/PageMetaData/PageMetaData.js";
import TitleMain from "../../components/TitleMain/TitleMain.js";

const Homepage = () => {
  return (
    <>
      <PageMetaData title={"Just a Bunch of Tables | jBot"} />
      <TitleMain>homepage</TitleMain>
    </>
  );
};

export default Homepage;
