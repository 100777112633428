import styled from "styled-components";

export const NewNoteIcon = styled.div`
  cursor: pointer;

  svg {
    opacity: 0.5;
    cursor: pointer;
    color: green;
  }
`;
